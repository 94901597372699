import { createMuiTheme } from '@material-ui/core/styles';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const black = '#2d2b2b';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    h1: { fontFamily: 'Lemon', color: black },
    h2: { fontFamily: 'Lemon', color: black },
    h3: { fontFamily: 'Lemon', color: black },
    h4: { fontFamily: 'Lemon', color: black },
    h5: { fontFamily: 'Lemon', color: black },
    h6: { fontFamily: 'Lemon', color: black },
    subtitle1: { fontFamily: 'Lemon', color: black },
    subtitle2: { fontFamily: 'Lemon', color: black },
    button: { fontFamily: 'Montserrat', fontWeight: 'bold' }
  },
  palette: {
    common: { black },
    primary: { main: '#fdd13f' },
    secondary: { main: '#8b2aa8' }
  },
  overrides: {
    MuiTypography: { colorPrimary: black },
    MuiCardContent: {
      root: { display: 'flex' }
    },
    MuiCard: {
      root: { display: 'flex' }
    },
    MuiButton: {
      root: { textTransform: 'none' }
    },
    MuiLink: {
      underlineHover: {
        '&:hover': { textDecoration: 'none', cursor: 'pointer' }
      }
    }
  }
});

export { theme };
